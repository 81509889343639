<template>
	<ul class="social">
		<li v-for="(social, i) in socialList" :key="i">
			<a :href="social.link" target="_blank" rel="noreferrer"
				><img class="svg" :src="social.src" alt="social"
			/></a>
		</li>
	</ul>
	<!-- End. social -->
</template>

<script>
export default {
	data() {
		return {
			socialList: [
				{
					link: "mailto:grsy.abdullah@gmail.com",
					src: require("../assets/img/svg/social/mail.svg"),
				},
				{
					link: "https://www.linkedin.com/in/abdullahgursoy/",
					src: require("../assets/img/svg/social/linkedin.svg"),
				},
				{
					link: "https://twitter.com/abdullahgursoy",
					src: require("../assets/img/svg/social/twitter.svg"),
				},
			],
		};
	},
};
</script>

<style lang="scss" scoped></style>
