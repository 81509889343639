<template>
	<div class="tokyo_tm_contact">
		<div class="tokyo_tm_title">
			<div class="title_flex">
				<div class="left">
					<span>Contact</span>
					<h3>Get in Touch</h3>
				</div>
			</div>
		</div>
		<!-- End Title -->

		<div class="fields">
			<ul class="social">
				<li v-for="(social, i) in socialList" :key="i">
					<a :href="social.link" target="_blank" rel="noreferrer">
						<img class="svg" :src="social.src" alt="social" />
						&nbsp; {{ social.name }}
					</a>
				</li>
			</ul>
		</div>
		<!-- END FIELDS -->
	</div>
</template>
<script>
export default {
	data() {
		return {
			socialList: [
				{
					name: "Mail",
					link: "mailto:grsy.abdullah@gmail.com",
					src: require("../assets/img/svg/social/mail.svg"),
				},
				{
					name: "Linkedin",
					link: "https://www.linkedin.com/in/abdullahgursoy/",
					src: require("../assets/img/svg/social/linkedin.svg"),
				},
				{
					name: "Twitter",
					link: "https://twitter.com/abdullahgursoy",
					src: require("../assets/img/svg/social/twitter.svg"),
				},
			],
		};
	},
};
</script>
