<template>
	<div class="tokyo_tm_about">
		<div class="description">
			<h3 class="name">Abdullah Gürsoy &amp; Software Developer | Artist</h3>
			<div class="description_inner">
				<div class="left">
					<p>
						I have 11 years experience in software development and 7 years in
						Javascript. I won a 100% Fulbright Scholarship and studied in the
						United States. I have a master's degree in Computer Science in the
						Computer Vision field.
						<br /><br />
						Over the years as a full stack developer I do not feel any
						difficulty to solve problems in Javascript, Vue.js, Node.js,
						Capacitor.js and PHP.
						<br /><br />
						Since 2 years I am working on Computer Vision on Web. I am
						passionate to work on Computer Vision field more.
					</p>
					<div class="tokyo_tm_button">
						<div style="display: flex">
							<button class="ib-button" @click="showModal">Read More</button>
							<button
								style="margin-left: 10px"
								class="ib-button"
								@click="downloadCV"
							>
								Download CV
							</button>
						</div>
					</div>
				</div>
				<!-- End .left -->

				<div class="right">
					<ul>
						<li>
							<p><span>Birthday:</span>05.02.1990</p>
						</li>
						<li>
							<p><span>Age:</span>33</p>
						</li>
						<li>
							<p><span>Address:</span>Istanbul, Turkey</p>
						</li>
						<li>
							<p>
								<span>Email:</span
								><a href="mailto:grsy.abdullah@gmail.com"
									>grsy.abdullah@gmail.com</a
								>
							</p>
						</li>
						<li>
							<p>
								<span>Phone:</span><a href="tel:+770221770505">+90 535 83 38</a>
							</p>
						</li>
						<li>
							<p><span>Study:</span>Master Degree & Beykent University</p>
						</li>
						<!--li>
							<p><span>Freelance:</span>Available</p>
						</li -->
					</ul>
					<!-- End ul -->
				</div>
				<!-- End .right -->
			</div>
		</div>

		<!-- Start Modalbox -->
		<transition name="fade">
			<!-- Modal -->
			<div
				:class="{ 'modal-mask': isActive }"
				id="modal"
				@click="closeModal"
				v-if="isVisible"
			>
				<div
					class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
					@click.stop
				>
					<div class="modal-content">
						<div class="tokyo_tm_modalbox_about">
							<div class="close" @click="closeModal">
								<img
									class="svg"
									src="../assets/img/svg/cancel.svg"
									alt="cancel-img"
								/>
							</div>
							<!-- End .close -->
							<div class="my_box">
								<div class="left">
									<div class="about_title">
										<h3>Software Skills</h3>
									</div>
									<!-- End .abut_title -->
									<div class="tokyo_progress">
										<div class="progress_inner">
											<span
												><span class="label">Javascript</span
												><span class="number">100%</span></span
											>
											<div class="background">
												<div class="bar">
													<div class="bar_in" style="width: 100%"></div>
												</div>
											</div>
										</div>
										<!-- End progress_inner -->
										<div class="progress_inner">
											<span
												><span class="label">Vue.js</span
												><span class="number">100%</span></span
											>
											<div class="background">
												<div class="bar">
													<div class="bar_in" style="width: 100%"></div>
												</div>
											</div>
										</div>
										<!-- End progress_inner -->
										<div class="progress_inner">
											<span
												><span class="label">Laravel</span
												><span class="number">100%</span></span
											>
											<div class="background">
												<div class="bar">
													<div class="bar_in" style="width: 100%"></div>
												</div>
											</div>
										</div>
										<!-- End progress_inner -->
										<div class="progress_inner">
											<span
												><span class="label">Computer Vision On Web</span
												><span class="number">100%</span></span
											>
											<div class="background">
												<div class="bar">
													<div class="bar_in" style="width: 100%"></div>
												</div>
											</div>
										</div>
										<!-- End progress_inner -->
									</div>
									<!-- End .tokyo_progress -->
								</div>
								<!-- End .left -->
								<div class="right">
									<div class="about_title">
										<h3>Language Skills</h3>
									</div>
									<div class="tokyo_progress">
										<div class="progress_inner">
											<span
												><span class="label">English</span
												><span class="number">95%</span></span
											>
											<div class="background">
												<div class="bar">
													<div class="bar_in" style="width: 95%"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<!-- End .right -->
							</div>
							<div class="counter">
								<div class="about_title">
									<h3>Facts</h3>
								</div>
								<ul>
									<li>
										<div class="list_inner">
											<h3>20+</h3>
											<span>Large Projects Completed</span>
										</div>
									</li>
									<li>
										<div class="list_inner">
											<h3>10</h3>
											<span>Happy Clients</span>
										</div>
									</li>
									<li>
										<div class="list_inner">
											<h3>100K+</h3>
											<span>Lines of Code</span>
										</div>
									</li>
								</ul>
								<!-- End ul -->
							</div>
							<!-- End .counter -->
							<!-- div class="partners">
								<div class="about_title">
									<h3>Our Partners</h3>
								</div>
								<Brand />
							</div -->
							<!-- End .partner -->
						</div>
						<!-- End .tokyo_tm_modalbox_about -->
					</div>
				</div>
			</div>
		</transition>
		<!-- End Modalbox -->
	</div>
</template>

<script>
// import Brand from "./Brand";
export default {
	components: {
		// Brand,
	},
	data() {
		return {
			isActive: false,
			isVisible: false,
		};
	},
	methods: {
		downloadCV: function () {
			window.location.href = "/files/CV.pdf";
		},
		showModal: function () {
			this.isActive = true;
			this.isVisible = true;
		},
		closeModal: function () {
			this.isActive = false;
			this.isVisible = false;
		},
	},
};
</script>

<style lang="scss"></style>
