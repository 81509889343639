<template>
	<div class="tokyo_tm_home">
		<div class="home_content">
			<div class="avatar">
				<div
					class="image avatar_img"
					:style="{ backgroundImage: 'url(' + src1 + ')' }"
				></div>
			</div>
			<!-- End .avatar -->
			<div class="details">
				<h3 class="name">Abdullah Gürsoy</h3>

				<h5 class="">Software Developer & Artist</h5>
				<br />

				<p class="job">
					I have 11 years experience in software development and 7 years in
					Javascript. I won a 100% Fulbright Scholarship and studied in the
					United States. I have a master's degree in Computer Science in the
					Computer Vision field.
					<br /><br />
					Over the years as a full stack developer I do not feel any difficulty
					to solve problems in Javascript, Vue.js, Node.js, Capacitor.js and
					PHP.
					<br /><br />
					Since 2 years I am working on Computer Vision on Web. I am passionate
					to work on Computer Vision field more.
				</p>

				<Social />
			</div>
			<!-- End .details -->
		</div>
	</div>
</template>

<script>
import Social from "./Social";
export default {
	components: {
		Social,
	},
	data() {
		return {
			src1: require("../assets/img/slider/1.jpg"),
		};
	},
};
</script>

<style lang="scss"></style>
