<template>
	<div class="tokyo_tm_news" v-show="!loading">
		<div class="tokyo_tm_title">
			<div class="title_flex">
				<div class="left">
					<span>Portfolio</span>
					<h3>{{ allItems.length }} Large Scale Projects</h3>
				</div>
			</div>
		</div>
		<ul class="tokyo_tm_news_inner flex-wrap">
			<li v-for="(item, itemKey) in allItems" :key="itemKey">
				<div class="list_inner">
					<div class="image">
						<div class="main" @click="showModal(item)"></div>
					</div>
					<!-- End .image -->
					<div class="details">
						<div class="extra">
							<p class="date">Client: {{ item.attributes.client || "" }}</p>
						</div>
						<div>
							<div style="position: relative">
								<span
									v-if="item.attributes.video.data"
									@click="showModal(item)"
									class="video-layer"
								></span>
								<img
									@click="showModal(item)"
									height="200px"
									width="100%"
									style="object-fit: cover; cursor: pointer"
									:src="`${apiUrl}${item.attributes.screenshots.data[0].attributes.url}`"
								/>
							</div>
						</div>
						<br />
						<!-- End meta info -->
						<h3 class="title" @click="showModal(item)">
							{{ item.attributes.name }}
						</h3>
						<hr />
						<span>
							<b>Description:</b> &nbsp;{{ item.attributes.description }}
						</span>
						<hr />
						<span>
							{{ item.attributes.role_in_project }}
						</span>
						<br />
						<br />
						<div class="tokyo_tm_read_more">
							<a
								v-if="item.attributes.project_link"
								:href="item.attributes.project_link"
								target="_blank"
								><span>project link</span></a
							>
						</div>
					</div>
					<!-- End .details -->
				</div>
			</li>
			<!-- End single blog -->
		</ul>
		<!-- Start first modal -->
		<modal
			id="modal"
			name="portfolio"
			:width="'80%'"
			:height="'auto'"
			:classes="['modal-custom']"
		>
			<div v-if="selectedItem" class="modal-content">
				<div class="tokyo_tm_modalbox_news">
					<div class="close" @click="closeModal" style="padding-bottom: 10px">
						<img
							class="svg"
							src="../assets/img/svg/cancel.svg"
							alt="cancel-img"
						/>
					</div>

					<div>
						<div v-if="selectedItem.attributes.video.data">
							<video
								controls
								autoplay="true"
								:src="`${apiUrl}${selectedItem.attributes.video.data.attributes.url}`"
								width="100%"
								height="500"
							></video>
						</div>

						<carousel v-else :perPage="1" class="carousel-container">
							<slide
								v-for="(screenshot, screenshotKey) in selectedItem.attributes
									.screenshots.data"
								:key="screenshotKey"
							>
								<img
									:src="`${apiUrl}${screenshot.attributes.url}`"
									:alt="`Portfolio-${screenshotKey}`"
									class="carousel-images"
								/>
							</slide>
						</carousel>
					</div>

					<div class="details">
						<div class="extra">
							<p class="date project-link">
								<a
									v-if="selectedItem.attributes.project_link"
									:href="selectedItem.attributes.project_link"
									class="link"
									>Project Link</a
								>
							</p>
							<p class="date project-client">
								Client: {{ selectedItem.attributes.client }}
							</p>
						</div>
						<h3 class="title">
							{{ selectedItem.attributes.name }}
						</h3>
					</div>

					<div class="main_content">
						<div class="descriptions">
							<p class="bigger">
								{{ selectedItem.attributes.description }}
							</p>

							<div class="role">
								<h5 class="role-title">Role in project:</h5>
								<p>{{ selectedItem.attributes.role_in_project }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</modal>
		<!-- End second modal -->
	</div>
</template>

<script>
import axios from "axios";

export default {
	data() {
		return {
			loading: true,
			apiUrl: "https://gursoy.me/strapi",
			allItems: [],
			selectedItem: null,
			src: require("../assets/img/news/1.jpg"),
			popup: require("../assets/img/news/1.jpg"),
		};
	},
	methods: {
		showModal: function (item) {
			this.selectedItem = item;
			this.$modal.show("portfolio");
		},
		closeModal: function () {
			this.selectedItem = null;
			this.$modal.hide("portfolio");
		},
	},
	mounted() {
		axios
			.get(
				`${this.apiUrl}/api/portfolio-queue?populate=*&pagination[limit]=100`
			)
			.then((response_1) => {
				const order = response_1.data.data.attributes.portfolios.data.map(
					(obj) => {
						return obj.id;
					}
				);

				axios
					.get(
						`${this.apiUrl}/api/portfolios?pagination[limit]=100&sort[0]=createdAt:desc&populate=*`
					)
					.then((response_2) => {
						let _data = response_2.data.data.map((obj) => {
							return obj;
						});

						_data = _data.sort(
							(a, b) => order.indexOf(a.id) - order.indexOf(b.id)
						);

						this.allItems = _data;
						this.loading = false;
					});
			});
	},
};
</script>

<style lang="scss">
.VueCarousel-slide {
	position: relative;
	text-align: center;
	min-height: 100px;
}

.link {
	text-decoration: underline !important;
}

.video-layer {
	cursor: pointer;
	width: 100%;
	height: 100%;
	position: absolute;
	background: url("/icons/play-btn.png") no-repeat;
	background-position: 50% 50%;
	background-size: 300%;
}

.role {
	margin-top: 30px;
}

.role-title {
	margin-bottom: 30px;
}

.carousel-container {
	margin-bottom: 30px;
}

.carousel-images {
	height: 300px;
	object-fit: cover;
}

.modal-content::-webkit-scrollbar {
	display: none;
}

.tokyo_tm_news .tokyo_tm_modalbox_news .details .title {
	padding-top: 15px;
}

@media only screen and (min-width: 768px) {
	.flex-wrap {
		display: flex !important;
		flex-wrap: wrap !important;
	}
}

@media screen and (max-width: 575px) {
	.extra {
		margin-bottom: 5px !important;
	}

	.project-client {
		font-size: 10px !important;
	}

	.project-link {
		font-size: 10px !important;
	}

	.carousel-container {
		margin-bottom: 15px;
	}

	.carousel-images {
		height: 200px;
		object-fit: cover;
	}

	.vm--modal {
		top: 120px !important;
		padding: 10px 10px 10px 10px !important;
		border-radius: 10px !important;
	}

	.modal-content {
		height: 600px !important;
		border: white !important;
	}

	.modal-content h3 {
		font-size: 15px !important;
	}

	.modal-content p {
		font-size: 14px !important;
	}

	.tokyo_tm_news .tokyo_tm_modalbox_news .details {
		margin-bottom: 5px !important;
	}

	.tokyo_tm_modalbox_news .main_content .descriptions .bigger {
		margin-bottom: 15px !important;
	}

	.role {
		margin-top: 15px !important;
	}

	.role-title {
		margin-bottom: 5px !important;
	}
}
</style>
